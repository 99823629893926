<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight2 plr13 bbox pb20">
      <div class="list pt10"
           v-if="list.length > 0">
        <div class="item flex justify-between align-center"
             v-for="item in list"
             :key="item.id">
          <div class="text-cut">
            <!-- <img
              src="../../assets/file/ppt.png"
              alt=""
              width="16"
              height="18"
              class="vm"
            /> -->
            <span class="f14 vm ml10">{{ item.machineType }}</span>
            <p class="f13 text999 mt5 ml10">{{ item.typeName }}</p>
          </div>
          <i class="el-icon-star-on f30 text-blue likeTool ml10"
             @click.stop="delLike(item,item.machineType,item.typeName)"></i>
        </div>
      </div>
      <!-- 这里的加载中的插件需要去入口文件引入vant组件 -->
      <van-loading type="spinner"
                   color="white"
                   size="40px"
                   style="position:fixed;top:0;left:0;"
                   v-if="showLoading" />
      <!-- 结果为空 -->
      <div v-if="list.length == 0"
           class="kong positiona">
        <img src="../../assets/images/kong.png"
             alt=""
             width="150" />
        <div class="f15 textc mt15 text999">{{$t('my.noTestLogging')}}</div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer class="positionf footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
import { Dialog } from "vant";
export default {
  components: { Header, Footer },
  data () {
    return {
      list: [],
      currPage: 1,
      totalPage: 1,
      pageSize: 30,
      showLoading: false,
    };
  },
  methods: {
    // 取消收藏
    delLike (item, machineType, typeName) {
      Dialog.confirm({
        title: "温馨提示",
        message: "确定取消收藏吗？",
      }).then(() => {
        var sendData = {};
        sendData.typeCode = item.typeCode;
        sendData.memberCode = localStorage.getItem("memberCode");
        var that = this;
        this.$http.post("memberCollect/deleteMemberCollect", sendData).then(function (res) {
          if (200 == res.data.code) {
            var index = that.list.indexOf(item);
            that.list.splice(index, 1);
            Toast("取消成功");
          }
        });
      });
    },
    onScroll () {
      console.log("滚动了")
      // 可滚动容器的高度
      var innerHeight = document.querySelector('.BoxHeight2').clientHeight
      // 屏幕尺寸高度
      var outerHeight = document.documentElement.clientHeight - 70;
      // 可滚动容器超出当前窗口显示范围的高度
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight <= (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      console.log('可滚动容器的高度:' + innerHeight, '屏幕尺寸高度:' + outerHeight, '可滚动容器超出当前窗口显示范围的高度:' + scrollTop)
      if (outerHeight + scrollTop >= innerHeight) {
        console.log("触底了")
        // 加载更多操作
        this.currPage++;
        this.loadMemberCollectList();
      }
    },
    loadMemberCollectList () {
      this.showLoading = true;
      var sendData = {};
      sendData.currPage = this.currPage;
      sendData.pageSize = this.pageSize;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http.post("memberCollect/queryMemberCollectPage", sendData).then(function (res) {
        if (200 == res.data.code) {
          // that.list = res.data.data.data;
          for (var i = 0; i < res.data.data.data.length; i++) {
            that.list.push(res.data.data.data[i]);
          }
          that.currPage = res.data.data.currPage;
          that.totalPage = res.data.data.pageCount;
          that.showLoading = false;
        }
      });
    },
    // 跳转到考试
    gotoExamination (id) {
      this.$router.push({ path: "/testDetail", query: { id: id } });
    },
  },
  created () {
    this.loadMemberCollectList();
  }
};
</script>

<style scoped>
.item {
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
}
.item:last-of-type {
  border-bottom: none;
}
.footer {
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>